import React from 'react';
import Navbar from './Components/Navbar.jsx';
import Education from './Components/Education.jsx';
import Experience from './Components/Experience.jsx';
import Skills from './Components/Skills.jsx';
import Projects from './Components/Projects.jsx';
import ContactMe from './Components/ContactMe.jsx';
import About from './Components/About.jsx';

function App() {
  return (
    <div className='overflow-x-hidden text-neutral-300 antialiased selection:bg-[#0f5f99]'>
      <div className='fixed top-0 -z-10 h-full w-full'>
        <div className="absolute inset-0 -z-10 h-full w-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]"></div>
      </div>
      <div id="about" className='container mx-auto px-8 pt-24'>
        <Navbar />
        <About />
        <Education />
        <Experience />
        <Skills />
        <Projects />
        <ContactMe />
      </div>
    </div>
  );
}

export default App;
