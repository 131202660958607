import { EDUCATION } from "../Constants/constants"
import { motion } from "framer-motion"

export default function Education() {
    return (
        <section id="education" >
            <div className="border-b border-neutral-900 pb-4 py-20">
                <h1 className="my-20 text-center text-4xl">Education</h1>
                {EDUCATION.map((education, index) => (
                    <motion.div
                        whileInView={{ opacity: 1, x: 0 }}
                        initial={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.5 }}>
                        <div key={index} className="mb-8 flex flex-wrap lg:justify-center">
                            <div className="w-full lg:w-1/4">
                                <p className="mb-2 text-neutral-400 text-sm">{education.year}</p>
                            </div>
                            <div className="w-full max-w-xl lg:w-3/4">
                                <h6 className="mb-2 text-sm text-neutral-400">
                                    {education.institution} - {" "}
                                    <span className="text-sm text-purple-100">
                                        {education.degree}
                                    </span>
                                </h6>
                                <p className="mb-4 text-neutral-400" style={{ whiteSpace: 'pre-line' }}>{education.description}</p>
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </section>
    )
}