import { EXPERIENCE_CONTENT } from "../Constants/constants"
import { motion } from "framer-motion"

export default function Experience() {
    return (
        <section id="experience">
            <div className="border-b border-neutral-900 pb-4 py-20">
                <h1 className="my-20 text-center text-4xl">Experience</h1>
                {EXPERIENCE_CONTENT.map((experience, index) => (
                    <motion.div
                        whileInView={{ opacity: 1, x: 0 }}
                        initial={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.5 }}>
                        <div key={index} className="mb-8 flex flex-wrap lg:justify-center">
                            <div className="w-full lg:w-1/4">
                                <p className="mb-2 text-neutral-400 text-sm">{experience.year}</p>
                            </div>
                            <div className="w-full max-w-xl lg:w-3/4">
                                <h6 className="mb-2 text-sm text-neutral-400">
                                    {experience.role} - {" "}
                                    <span className="text-sm text-purple-100">
                                        {experience.company}
                                    </span>
                                </h6>
                                <p className="mb-4 text-neutral-400" style={{ whiteSpace: 'pre-line' }}>{experience.description}</p>
                                {experience.technologies.map((tech, index) => (
                                    <span key={index} className="mr-2 mt-4 rounded bg-neutral-900 px-2 py-1 text-sm font-medium text-purple-500">
                                        {tech}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </section>
    )
}