import React, { useState } from 'react';
import emailjs from "@emailjs/browser";

export default function ContactMe() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        const errors = {};
        if (!formData.name) errors.name = "Name is required";
        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email is invalid";
        }
        if (!formData.message) errors.message = "Message is required";
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        emailjs.send(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            {
                name: formData.name,  // Maps to ${name} in the template
                email: formData.email, // Maps to ${email} in the template
                message: formData.message // Maps to ${message} in the template
            },
            process.env.REACT_APP_PUBLIC_KEY
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert("Message sent successfully!");
                setFormData({ name: '', email: '', message: '' });
                setErrors({});
            })
            .catch((error) => {
                console.error('FAILED...', error);
                alert("Failed to send message.");
            });
    };

    return (
        <section id="contact-me" className="border-b border-neutral-900 pb-20">
            <div className="flex flex-col items-center justify-center">
                <h1 className="my-10 text-center text-4xl">Contact Me</h1>
                <form onSubmit={handleSubmit} className="max-w-xl justify-center items-center flex flex-wrap selection:bg-[#4ab3ff]">
                    <div className="mb-4 w-full">
                        <input
                            placeholder="Name"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-black"
                        />
                        {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                    </div>
                    <div className="mb-4 w-full">
                        <input
                            placeholder="Email"
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-black"
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                    </div>
                    <div className="mb-4 w-full">
                        <textarea
                            placeholder="Message"
                            type="text"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-black"
                        />
                        {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                    </div>
                    <button type="submit" className="w-1/4 hover:bg-purple-300 rounded-xl py-2 text-black text-lg text-bold bg-purple-500">Send</button>
                </form>
            </div>
        </section>
    )
}