import React from 'react';
import { Link } from 'react-scroll';
import logo from "../Assets/oz-hayun.png";
import { FaLinkedin, FaGithub, FaBars, FaTimes } from "react-icons/fa";
import { useState } from 'react';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const offsetValue = isOpen ? 20 : -70

    return (
        <nav className="fixed w-full top-0 left-0 bg-gradient-to-b from-slate-900 via-indigo-900 to-purple-800 py-2 z-50">
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center">
                    <Link to="about" smooth={true} duration={500} className="flex-shrink-0 cursor-pointer">
                        <img src={logo} alt="logo" className='h-16 w-auto' />
                    </Link>

                    <div className="flex items-center space-x-4 text-xl">
                        <a href="https://www.linkedin.com/in/ozhayun/">
                            <FaLinkedin />
                        </a>
                        <a href="https://github.com/ozhayun">
                            <FaGithub />
                        </a>
                        <div className='lg:hidden cursor-pointer text-2xl' onClick={toggleMenu}>
                            {isOpen ? <FaTimes /> : <FaBars />}
                        </div>
                    </div>
                </div>
                <div className={`flex-col lg:flex-row lg:flex justify-center items-center mt-2 lg:mt-0 ${isOpen ? 'flex' : 'hidden'} lg:flex lg:space-x-4 text-base`}>
                    <ul className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4 text-base">
                        <li>
                            <Link to="about" smooth={true} offset={offsetValue} duration={500} className="cursor-pointer hover:font-bold" onClick={toggleMenu}>About</Link>
                        </li>
                        <li>
                            <Link to="education" smooth={true} offset={offsetValue} duration={500} className="cursor-pointer hover:font-bold" onClick={toggleMenu}>Education</Link>
                        </li>
                        <li>
                            <Link to="experience" smooth={true} offset={offsetValue} duration={500} className="cursor-pointer hover:font-bold" onClick={toggleMenu}>Experience</Link>
                        </li>
                        <li>
                            <Link to="skills" smooth={true} offset={offsetValue} duration={500} className="cursor-pointer hover:font-bold" onClick={toggleMenu}>Skills</Link>
                        </li>
                        <li>
                            <Link to="projects" smooth={true} offset={offsetValue} duration={500} className="cursor-pointer hover:font-bold" onClick={toggleMenu}>Projects</Link>
                        </li>
                        <li>
                            <Link to="contact-me" smooth={true} offset={offsetValue} duration={500} className="cursor-pointer hover:font-bold" onClick={toggleMenu}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
