import project1 from "../Assets/projects/project-1.png";
import project2 from "../Assets/projects/project-2.png";

export const ABOUT_CONTENT = `
    I am a Computer Science graduate from The Academic College of Tel-Aviv, Yafo,
    with a strong foundation in software development and automation engineering. 
    During my service in the IDF, I honed my skills in developing comprehensive 
    automation frameworks and mentoring junior team members. I am passionate 
    about technology and enjoy using it to solve real-world problems. As a team 
    player, I am committed to continuous learning and contributing to innovative projects.
`;

export const EDUCATION = [
    {
        year: "2020 - 2024",
        institution: "The Academic College of Tel-Aviv, Yafo",
        degree: "B.Sc. in Computer Science",
        description: "Graduated with a solid foundation in software development, algorithms, data structures, and a strong focus on practical applications."
    },
    {
        year: "2021",
        institution: "Result Academy",
        degree: "NLP - Practitioner",
        description: `Learned techniques to help people navigate and overcome life’s challenges, aiding their personal development.

Acquired valuable skills in understanding and altering behavioral patterns, which improved my ability to communicate effectively and empathize with others.

The course emphasized tailoring strategies to suit personal requirements, sparking engagement and aiding in significant improvement.`
    },
    {
        year: "2016",
        institution: "IDF - BASMACH",
        degree: "Quality Assurance Course",
        description: "Completed a comprehensive course in Quality Assurance, laying the groundwork for my later work in both manual and automated testing."
    },
    {
        year: "2013 - 2016",
        institution: "Hadarim High School",
        degree: "High School Diploma",
        description: "Graduated with a focus on science and technology."
    },
];

export const EXPERIENCE_CONTENT = [
    {
        year: "2017 - 2019",
        role: "Automation Engineer",
        company: "IDF",
        description: `Designed and implemented comprehensive end-to-end automation frameworks and test cases using Java, enhancing testing efficiency and coverage.

Conducted in-depth analyses of specification documents to develop precise test scenarios, facilitating seamless integration of system functionalities.
Collaborated closely with developers and system analysts to ensure alignment with Agile methodologies.

Provided mentorship to junior team members, imparting knowledge on best practices in automation and the effective use of testing tools.`,
        technologies: ["Java", "Selenium", "LeanFT"],
    },
    {
        year: "2018",
        role: "Quality Assurance Instructor",
        company: "IDF - BASMACH",
        description: `Delivered a comprehensive software testing course, focusing on both manual and automated testing techniques, to 30 individuals from the Orthodox community, preparing them for roles across various IDF units.

Navigated the unique challenges of this role with a strong emphasis on teamwork, professionalism, and the ability to perform effectively under pressure.

Fostered a learning environment that encouraged creativity and critical thinking, while setting a high standard through personal conduct and commitment.`,
        technologies: ["Java", "Selenium", "SQL"],
    },
    {
        year: "2017",
        role: "Manual Quality Assurance",
        company: "IDF - MATZPAN",
        description: `Led the creation and running of test cases to thoroughly check the system and find any issues.

Worked closely with developers and system analysts to make sure testing plans matched what the system was supposed to do.

The job needed careful attention, the ability to solve problems, and good teamwork and communication to make sure our products were the best they could be.`,
        technologies: ["TFS", "MTM", "MongoDB"],
    },
];

export const PROJECTS = [
    {
        title: "Adventures in the Hospital",
        link: "https://asafharofewebapp1.web.app/",
        image: project1,
        description:
            `"Adventures in the Hospital" is specifically tailored to the process a child undergoes in the pediatric emergency department.
            The text found in pop-up messages throughout the game is written in a way that makes the processes in the emergency room accessible to the child in their own language. This helps to reduce anxiety and stress.`,
        technologies: ["JavaScript", "React", "CSS", "Firebase"],
    },
    {
        title: "CodeBlocks",
        link: "https://codeblocksharing.netlify.app/",
        image: project2,
        description:
            "A platform designed to facilitate code sharing between students and teachers, enhancing the educational experience by allowing real-time collaboration and feedback.",
        technologies: ["Node.js", "JavaScript", "React", "CSS", "MongoDB"],
    },
];