import { RiReactjsLine } from "react-icons/ri"
import { AiOutlineJavaScript } from "react-icons/ai";
import { AiOutlinePython } from "react-icons/ai";
import { AiOutlineDotNet } from "react-icons/ai";
import { FaNodeJs } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";

export default function Skills() {
    return (
        <section id="skills">
            <div className="container mx-auto px-4 border-b border-neutral-800 pb-14 py-20">
                <h1 className="my-20 text-center text-4xl">
                    Skills
                </h1>
                <div className="flex flex-wrap items-center justify-center gap-4">
                    <div className="rounded-2xl border-4 border-neutral-800 p-4">
                        <RiReactjsLine className="text-7xl text-cyan-400" />
                    </div>
                    <div className="rounded-2xl border-4 border-neutral-800 p-4">
                        <FaNodeJs className="text-7xl text-green-500" />
                    </div>
                    <div className="rounded-2xl border-4 border-neutral-800 p-4">
                        <AiOutlineJavaScript className="text-7xl text-white-100" />
                    </div>
                    <div className="rounded-2xl border-4 border-neutral-800 p-4">
                        <AiOutlinePython className="text-7xl" style={{
                            color: '#ffd343'
                        }} />
                    </div>
                    <div className="rounded-2xl border-4 border-neutral-800 p-4">
                        <AiOutlineDotNet className="text-7xl text-cyan-400" />
                    </div>
                    <div className="rounded-2xl border-4 border-neutral-800 p-4">
                        <SiMongodb className="text-7xl text-green-500" />
                    </div>

                </div>

            </div>

        </section>
    )
}