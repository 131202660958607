import React from 'react'
import { ABOUT_CONTENT } from "../Constants/constants"
import { motion } from "framer-motion"

const container = (delay) => ({
    hidden: { x: -70, opacity: 0 },
    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: 0.5, delay: delay }
    }
})

const About = () => {
    return (
        <div className='border-b border-neutral-900 pb-d lg:mb-35'>
            <div className='flex flex-wrap justify-center items-center'>
                <div className='w-full lg:w-1/2 text-center'>
                    <div className='flex flex-col items-center justify-center'>
                        <motion.h1
                            variants={container(0)}
                            initial="hidden"
                            animate="visible"
                            className='pb-16 text-6xl font-thin tracking-tight lg:mt-16 lg:text-8xl'>
                            Oz Hayun
                        </motion.h1>
                        <motion.span
                            variants={container(0.2)}
                            initial="hidden"
                            animate="visible"
                            className='bg-gradient-to-r from-pink-300 via-slate-500 to-purple-500 bg-clip-text text-3xl tracking-tight text-transparent'>
                            Software Developer
                        </motion.span>
                        <motion.p
                            variants={container(0.4)}
                            initial="hidden"
                            animate="visible"
                            className='my-2 max-w-3xl py-6 font-light tracking-tighter text-start'>
                            {ABOUT_CONTENT}
                        </motion.p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default About
